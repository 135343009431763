@import '@vg-constellation/angular-15/styles/c11n';
@import '~dialog-polyfill/dialog-polyfill.css';
//@import '@vg-constellation/styles/c11n';


body,
html {
  margin: 0;
  padding: 0;
  font-family: $c11n-font-family-sans;
}

a {
  text-decoration: none;
}

.text-hidden{
  font-size: 0;
  width: 0;
  height: 0;
  display: inline-block;
  overflow: hidden;
  position: absolute!important;
  border: 0!important;
  padding: 0!important;
  margin: 0!important;
  clip: rect(1px, 1px, 1px, 1px);
}
